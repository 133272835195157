 button.switch,
button.switch:hover,
button.switch:focus {
  outline: none;
   border: 1px solid grey;
  box-shadow: none;
}

button.switch > div {
    
  content: "";
  width: 20px;
  height: 20px;
  /* background-color: #9fa2ab; */
}
button.switch[aria-checked="true"] {
  background-color: #6A5BDA;
}
button.switch[aria-checked="true"] > div {
   
    position: relative;
    left: 5px;
}
button.switch[aria-checked="true"] > div:after {
   
    background-color: #F3F2FF;
}

button.switch[aria-checked="false"] {
  background-color: #ffffff;
  left: 18px;
}