@keyframes purpleL {
  from {
    position: absolute;
    left: 1vw;
    top: 1vw;
    width: 26vw;
    height: 26vw;
    max-width: 650px;
    max-height: 650px;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
  to {
    position: absolute;
    left: 4vw;
    top: 4vw;
    width: 20vw;
    height: 20vw;
    max-width: 500px;
    max-height: 500px;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
}
@-webkit-keyframes purpleL {
  from {
    position: absolute;
    left: 1vw;
    top: 1vw;
    width: 26vw;
    height: 26vw;
    max-width: 650px;
    max-height: 650px;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
  to {
    position: absolute;
    left: 4vw;
    top: 4vw;
    width: 20vw;
    height: 20vw;
    max-width: 500px;
    max-height: 500px;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
}
@keyframes purpleLmax {
  from {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 650px;
    height: 650px;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
  to {
    position: absolute;
    left: 105px;
    top: 105px;
    width: 500px;
    height: 500px;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
}
@-webkit-keyframes purpleLmax {
  from {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 650px;
    height: 650px;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
  to {
    position: absolute;
    left: 105px;
    top: 105px;
    width: 500px;
    height: 500px;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
}
@keyframes purpleS {
  from {
    position: absolute;
    left: 4vw;
    top: 4vw;
    width: 20vw;
    height: 20vw;
    max-width: 500px;
    max-height: 500px;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
  to {
    position: absolute;
    left: 6vw;
    top: 6vw;
    width: 16vw;
    height: 16vw;
    max-width: 400px;
    max-height: 400px;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
}
@-webkit-keyframes purpleS {
  from {
    position: absolute;
    left: 4vw;
    top: 4vw;
    width: 20vw;
    height: 20vw;
    max-width: 500px;
    max-height: 500px;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
  to {
    position: absolute;
    left: 6vw;
    top: 6vw;
    width: 16vw;
    height: 16vw;
    max-width: 400px;
    max-height: 400px;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
}
@keyframes purpleSmax {
  from {
    position: absolute;
    left: 105px;
    top: 105px;
    width: 500px;
    height: 500px;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
  to {
    position: absolute;
    left: 155px;
    top: 155px;
    width: 400px;
    height: 400px;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
}
@-webkit-keyframes purpleSmax {
  from {
    position: absolute;
    left: 105px;
    top: 105px;
    width: 500px;
    height: 500px;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
  to {
    position: absolute;
    left: 155px;
    top: 155px;
    width: 400px;
    height: 400px;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
}
@keyframes purpleL-s {
  from {
    position: absolute;
    left: 0;
    top: 0;
    width: 40vw;
    height: 40vw;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
  to {
    position: absolute;
    left: 5vw;
    top: 5vw;
    width: 30vw;
    height: 30vw;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
}
@-webkit-keyframes purpleL-s {
  from {
    position: absolute;
    left: 0;
    top: 0;
    width: 40vw;
    height: 40vw;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
  to {
    position: absolute;
    left: 5vw;
    top: 5vw;
    width: 30vw;
    height: 30vw;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 7;
  }
}
@keyframes purpleS-s {
  from {
    position: absolute;
    left: 5vw;
    top: 5vw;
    width: 30vw;
    height: 30vw;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
  to {
    position: absolute;
    left: 8vw;
    top: 8vw;
    width: 24vw;
    height: 24vw;
    background: #e9e5ff;
    border-radius: 50%;
    z-index: 8;
  }
}
@-webkit-keyframes purpleS-s {
  from {
    position: absolute;
    left: 5vw;
    top: 5vw;
    width: 30vw;
    height: 30vw;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 8;
  }
  to {
    position: absolute;
    left: 8vw;
    top: 8vw;
    width: 24vw;
    height: 24vw;
    background: #f7f6ff;
    border-radius: 50%;
    z-index: 8;
  }
}

.home-page {
  .top-main {
    width: 64vw;
    max-width: 1600px;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0;
    margin: 0 auto 0;
    box-sizing: border-box;
    .top-left {
      width: 25.4vw;
      max-width: 637px;
      min-width: 300px;
      .top-text {
        font-size: 64px;
        font-family: Aileron-SemiBold, Aileron;
        font-weight: 600;
        color: #141414;
        line-height: 75px;

        z-index: 10;
        margin-bottom: 38px;
        text-align: left;
      }
      .top-sub-text {
        font-size: 18px;
        font-family: Aileron-Light, Aileron;
        font-weight: 300;
        color: #383838;
        line-height: 21px;
        margin-bottom: 38px;
        text-align: left;
      }
      .top-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 400px;

        z-index: 10;
        flex-shrink: 0;
        > div {
          width: 174px;
          height: 60px;
          background: #6a5bda;
          border-radius: 6px;

          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 4px 8px 0px rgba(105, 58, 199, 0.6);
          cursor: pointer;
          margin-bottom: 20px;
          > img {
            width: 32px;
            height: 32px;
            margin-right: 5px;
          }
          > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            > div:first-child {
              font-size: 12px;
              font-family: Aileron-Light, Aileron;
              font-weight: 300;
              color: #fff;
              line-height: 14px;
            }
            > div:last-child {
              font-size: 20px;
              font-family: Aileron-Regular, Aileron;
              font-weight: 400;
              color: #fff;
              line-height: 23px;
            }
          }
        }
      }
    }
    .top-right {
      min-width: 450px;
      min-height: 437px;
      margin: 0 auto;
      background-image: url("../../assets/top_r_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 37.04vw;
      height: 36vw;
      max-width: 926px;
      max-height: 900px;
    }
  }
  .pg-main2 {
    position: relative;
    background: #fafafa;
    padding-bottom: 20px;
    width: 100vw;
    max-width: 100vw;
    .main-text {
      width: 64vw;
      max-width: 1600px;
      margin: 0 auto;
      padding-top: 73px;
      text-align: center;
      .main-title {
        max-width: 1600px;
        margin: 0 auto;
        font-size: 60px;
        font-family: Inter-Bold, Inter;
        font-weight: bold;
        color: #141414;
        line-height: 70px;
      }
    }
    .main-img {
      width: 64vw;
      max-width: 1600px;
      margin: 85px auto 125px;
      position: relative;
      .main-center {
        position: absolute;
        top: -4vw;
        left: 18vw;
        width: 28vw;
        max-width: 711px;
        > div {
          position: relative;
          width: 28vw;
          max-width: 711px;
          height: 546px;
          display: flex;
          align-content: center;
          justify-content: center;
          .purple-l {
            position: absolute;
            left: 1vw;
            top: 1vw;
            width: 26vw;
            height: 26vw;
            background: #f7f6ff;
            border-radius: 50%;
            z-index: 7;
            animation: purpleL 2s linear 0s infinite alternate;
            -webkit-animation: purpleL 2s linear 0s infinite alternate; /* Safari 与 Chrome */
          }
          .purple-s {
            position: absolute;
            left: 4vw;
            top: 4vw;
            width: 20vw;
            height: 20vw;
            background: #e9e5ff;
            border-radius: 50%;
            z-index: 8;
            animation: purpleS 2.5s linear 0s infinite alternate;
            -webkit-animation: purpleS 2.5s linear 0s infinite alternate; /* Safari 与 Chrome */
          }
          > img {
            position: absolute;
            left: 6vw;
            top: 6vw;
            z-index: 9;
            width: 15.8vw;
            max-width: 396px;
            height: 15.8vw;
            max-height: 396px;
          }
        }
      }
      .img6 {
        width: 64vw;
        max-width: 1600px;
        height: 21vw;
        max-height: 525px;
      }
    }
  }
  .pg-main3 {
    width: 64vw;
    max-width: 1600px;
    margin: 0 auto;
    .main-text {
      width: 64vw;
      max-width: 1600px;
      padding-top: 73px;
      padding-bottom: 69px;
      .main-title {
        font-size: 54px;
        font-family: Inter-Bold, Inter;
        font-weight: bold;
        color: #141414;
        line-height: 63px;
        text-align: center;
      }
      .main-sub-title {
        font-size: 64px;
        font-family: Inter-Black, Inter;
        font-weight: 900;
        color: #141414;
        line-height: 75px;
        margin: 28px auto 69px;
        text-align: center;
        > span {
          color: #6a5bda;
        }
      }
    }
    .main-flex {
      width: 64vw;
      max-width: 1600px;
      margin: 0 auto;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-img {
          width: 21.12vw;
          height: 25vw;
          max-width: 528px;
          max-height: 625px;
          > img {
            width: 21.12vw;
            height: 25vw;
            max-width: 528px;
            max-height: 625px;
          }
        }
      }
      .item-left,
      .item-right {
        .item-title {
          width: 31vw;
          max-width: 777px;
          text-align: left;
          font-size: 48px;
          font-family: Inter-Bold, Inter;
          font-weight: bold;
          color: #141414;
          line-height: 56px;
        }
        .item-btn {
          margin-top: 39px;
          height: 54px;
          background: #6a5bda;
          border-radius: 6px;
          /* padding:0 39px; */
          width: 199px;
          text-align: center;
          font-size: 20px;
          font-family: Inter-Semi Bold, Inter;
          font-weight: normal;
          color: #ffffff;
          line-height: 54px;
          cursor: pointer;
        }
        .item-text {
          margin-top: 27px;
          width: 31vw;
          max-width: 777px;
          text-align: left;
          font-size: 20px;
          font-family: Inter-Regular, Inter;
          font-weight: 400;
          color: #242424;
          line-height: 23px;
        }
      }
      > div:last-child {
        margin: 131px auto 144px;
        .item-img {
          width: 25vw;
          height: 21.9vw;
          max-width: 625px;
          max-height: 546px;
          > img {
            width: 25vw;
            height: 21.9vw;
            max-width: 625px;
            max-height: 546px;
          }
        }
      }
    }
  }
  .pg-main4 {
    background: #7766f3;
    width: 100vw;
    max-width: 100vw;
    .top-main4 {
      width: 64vw;
      max-width: 1600px;
      margin: 0 auto;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      box-sizing: border-box;
      margin-top: 5vw;
      padding-top: 100px;
      .top-left {
        max-width: 572px;
        width: 22.8vw;
        .top-text {
          display: flex;
          align-items: flex-end;
          margin-bottom: 38px;
          > div {
            width: 22.8vw;
            font-size: 42px;
            font-family: Aileron-SemiBold, Aileron;
            font-weight: 600;
            color: #fff;
            line-height: 75px;
            max-width: 572px;
            z-index: 10;
            text-align: left;
          }
          > img {
            width: 2.7vw;
            height: 2.7vw;
            margin-bottom: 11px;
          }
        }
        .top-sub-text {
          width: 22.8vw;
          max-width: 572px;
          font-size: 18px;
          font-family: Aileron-Light, Aileron;
          font-weight: 300;
          color: #fff;
          line-height: 21px;
          margin-bottom: 38px;
          text-align: left;
        }
        .top-btns {
          width: 22.8vw;
          max-width: 400px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          z-index: 10;
          margin-bottom: 5vw;
          > div {
            width: 174px;
            height: 60px;
            background: #1f1c35;
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 4px 8px 0px rgba(105, 58, 199, 0.6);
            margin-bottom: 10px;
            > div {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              > div:first-child {
                font-size: 12px;
                font-family: Aileron-Light, Aileron;
                font-weight: 300;
                color: #ffffff;
                line-height: 14px;
              }
              > div:last-child {
                font-size: 20px;
                font-family: Aileron-Regular, Aileron;
                font-weight: 400;
                color: #ffffff;
                line-height: 23px;
              }
            }

            > img {
              width: 32px;
              height: 32px;
              margin-right: 5px;
            }
          }
        }
      }
      .top-right {
        max-width: 960px;
        width: calc(41.32vw - 70px);
        background-image: url("../../assets/ex_img.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        height: 32.32vw;
        max-height: 808px;
      }
    }
  }
}

/* 夜间 */
.home-page-n {
  .top-main {
    .top-left {
      .top-text {
        color: #ffffff;
      }
      .top-sub-text {
        color: #ffffff;
      }
      .top-btns {
        > div {
          background: #6a5bda;
          box-shadow: 0px 4px 8px 0px rgba(105, 58, 199, 0.6);

          > div {
            > div:first-child {
              color: #fff;
            }
            > div:last-child {
              color: #fff;
            }
          }
        }
      }
    }
    .top-right {
      min-width: 450px;
      min-height: 437px;
      margin: 0 auto;
      background-image: url("../../assets/top_r_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 37.04vw;
      height: 36vw;
      max-width: 926px;
      max-height: 900px;
    }
  }
  .pg-main2 {
    background: #2f2f2f;
    .main-text {
      .main-title {
        color: #ffffff;
      }
    }
    .main-img {
      .main-center {
        > div {
          .purple-l {
            background: #f7f6ff;
          }
          .purple-s {
            background: #e9e5ff;
          }
        }
      }
      .img6 {
        width: 64vw;
        max-width: 1600px;
        height: 21vw;
        max-height: 525px;
      }
    }
  }
  .pg-main3 {
    .main-text {
      .main-title {
        color: #ffffff;
      }
      .main-sub-title {
        color: #ffffff;
        > span {
          color: #6a5bda;
        }
      }
    }
    .main-flex {
      .item-left,
      .item-right {
        .item-title {
          color: #ffffff;
        }
        .item-btn {
          background: #6a5bda;
          color: #ffffff;
        }
        .item-text {
          color: #ffffff;
        }
      }
    }
  }
  .pg-main4 {
    background: #1f1c35;
    .top-main4 {
      .top-left {
        .top-text {
          > div {
            color: #fff;
          }
        }
        .top-sub-text {
          color: #fff;
        }
        .top-btns {
          > div {
            background: #6a5bda;
            box-shadow: 0px 4px 8px 0px rgba(105, 58, 199, 0.6);
            > div {
              > div:first-child {
                color: #ffffff;
              }
              > div:last-child {
                color: #ffffff;
              }
            }
          }
        }
      }
      .top-right {
        background-image: url("../../assets/ex_img_n.png");
      }
    }
  }
}
