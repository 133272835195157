

@media screen and (min-width: 800px) and (max-width: 1600px) {
  .web-page{
    width: 95vw !important;    
    max-width: 1440px !important;

  }
}
@media screen and (min-width: 1601px) {
  .web-page{
    width: 1440px !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 800px) {
  .web-page{
    width: 95vw !important;
    max-width: 800px !important;
    min-width: 0px !important;
  }
}
.react-datepicker-wrapper{
  width: 100%;
  height: 100%;
}
.react-datepicker__input-container{
  width: 100%;
  height: 100%;
  
}
.date-picker{
  width: 100%;
  height: 100%;
  color:#000;
  padding: 0 10px;
  box-sizing: border-box;
  background: transparent;
  border: none;
  
}
.date-picker-w{
  width: 100%;
  height: 100%;
  color:#fff;
  padding: 0 10px;
  box-sizing: border-box;
  background: transparent;
  border: none;
  
}

.date-picker-divw{
  z-index: 9999;
  .react-datepicker{
    z-index: 9999;

  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    left: -12px !important;
    border-bottom-color: transparent !important;
  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    left: -12px !important;
    border-bottom-color: transparent !important;
  }
  .react-datepicker{
    background: #212429 !important;
    color:#C3C5CB !important;
    z-index: 9999;
  
  }
  .react-datepicker__day,.react-datepicker__day-name,.react-datepicker__current-month{
    color:#C3C5CB !important;
    
  }
  .react-datepicker__day:hover{
    color:#000 !important;
  }
  .react-datepicker__header{
    background: #212429 !important;
    color:#C3C5CB !important;
  }
}

.date-picker-div{
  z-index: 9999;
  .react-datepicker{
    z-index: 9999;

  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    left: -12px !important;
    border-bottom-color: transparent !important;
  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    left: -12px !important;
    border-bottom-color: transparent !important;
  }
}
#actmain::-webkit-scrollbar {
  display: none;
}
.main-item:hover{
  .item-bottom-none{
    // height:110px;
    bottom:35px;
  }
  .item-bottom{
    height:110px;
    bottom:0;
  }
  .item-bottom-name{
    height:85px;
    bottom:0;
  }
  .buy-btn{
    height:30px;
    opacity: 1;
  }
}
.body-wrapper::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.body-wrapper {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.scrollbar::-webkit-scrollbar{
  width:10px;
  height:10px;
  /**/
}
.scrollbar::-webkit-scrollbar-track{
  background: rgb(239, 239, 239);
  border-radius:2px;
}
.scrollbar::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
.scrollbar::-webkit-scrollbar-thumb:hover{
  background: #333;
}
.scrollbar::-webkit-scrollbar-corner{
  background: #179a16;
}
