@font-face {
    font-family: frick3;
    src: url('../../assets/Frick/Frick3.otf');
  }
  .fontFrk3{
    font-family: frick3;
  }

  @media screen and (min-width: 800px) and (max-width: 1600px) {
    .web-page{
      width: 95vw !important;
      max-width: 1440px !important;
    }
  }
  @media screen and (min-width: 1601px) {
    .web-page{
      width: 1440px !important;
    }
  }