@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/violet.css';
@import '@radix-ui/colors/mauve.css';
/* reset */
button {
  all: unset;
}

.PopoverTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px; */
  /* background-color: white;
  color: var(--violet-11); */
  box-shadow: 0 2px 10px var(--black-a7);
}
.PopoverTrigger:hover {
  /* background-color: var(--mauve-3); */
}
.PopoverTrigger:focus {
  /* box-shadow: 0 0 0 2px black; */
}

.PopoverContent {
  /* border-radius: 4px;
  padding: 20px;
  width: 260px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  background-color: white; */
  /* box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px; */
  z-index: 999;
}
.PopoverContent:focus {
  outline: none;
  /* box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
    0 0 0 2px var(--violet-7); */
}

.PopoverArrow {
  fill: white;
}